.selectedWork {
  padding-top: 3rem;
}

.imageCardContainer {
  position: relative;
}

.imageCard {
  margin-bottom: 2rem;
  position: sticky;
  top: 5rem;
  width: 100%;
  height: 80vh;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  overflow: hidden;
  padding: 2rem;
}

.cardFlex {
  gap: 1rem;
  justify-content: flex-start;
  height: 100%;
  width: 100%; 
}

.cardLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  bottom: 3.6rem;
  left: 4rem;
  width: auto;
  max-width: 45rem;
}
.cardLeft h1 {
  color: var(--color-light);
  line-height: 3rem;
  font-size: 2rem;
}

.cardLeft p {
  max-width: fit-content;
  font-weight: lighter;
}

.cardButton {
  margin-top: 0.5rem;
  align-self: flex-start;
  width: fit-content;
}

@media (max-width: 1024px) {
 .mobileVisibility {
  justify-content: flex-start;
  display: none;
 }
 
 .imageCard {
  max-height: 30rem;
  padding: 1rem;
}

.cardLeft {
  padding: 0.5rem 1rem;
  position: absolute;
  bottom: 1.5rem;
  left: 1.5rem;
}

}
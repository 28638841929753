.mainTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5rem 35rem;
  background-color: var(--color-primary);
  max-width: 100%;
  margin: 0 auto;
}

.mainTitle h1 {
  min-block-size: 18vh;
  max-width: 65rem;
  font-weight: normal;
}

.mainTitle p {
  line-height: 2rem;
  font-size: 1.6rem;
  max-width: 45rem;
  color: var(--color-grey-2);
  padding-top: 0.5rem;
}

.homeImage {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  mix-blend-mode: screen;
  filter: contrast(1.2) grayscale(100%);
}

.imageContainer {
  max-width: 40%;
  position: relative;
}

.imageContainer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

/* Small Devices */
@media (max-width: 1024px)  {
  .mainTitle {
    padding: 2rem;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    text-align: center;
  }

  .mainTitle h1 {
    text-align: center;
    min-block-size: 20vh;
    font-weight: 500;
    line-height: 3.3rem;
    font-size: 3rem;
  }

  .mainTitle p {
    font-size: 1.4rem;
    line-height: 1.8rem;
    max-width: 100%;
  }

  .imageContainer {
    max-width: 100%;
    margin-top: 2rem;
  }
}

/* Normal Devices */
@media (min-width: 1025px) and (max-width: 2000px) {
  .mainTitle {
    padding: 5rem 16rem;
    margin: 0 auto;
  }

  .mainTitle h1 {
    min-block-size: 25vh;
  }
}

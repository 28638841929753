.success-message {
  display: none;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%) translateY(20px);
  background: var(--transparent-1);
  border: 1px solid var(--color-success);
  backdrop-filter: blur(10px);
  padding: 1rem 2rem;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  font-size: 1rem;
  text-align: center;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  opacity: 0; /* Invisível no início */
  visibility: hidden; /* Oculto visualmente */
  transition: opacity 0.5s ease, transform 0.5s ease, visibility 0.5s ease;
}

.success-message.show {
  display: flex;
  gap: 0.3rem;
  opacity: 1;
  visibility: visible;
  align-items: center;
  transform: translateX(-50%) translateY(0);
}

.error {
  border: 1px solid var(--color-error) !important; 
}

.error-message {
  margin-top: -0.5rem;
  color: var(--color-error);
  font-size: 0.875rem;
}

.primaryCard {
  background-color: transparent;
  padding: 2rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.contactForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.contactForm h1 {
  font-size: 36px;
  line-height: 1;
  margin-bottom: 2rem;
}

.contactFormElements {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
}

.contactForm input, .messageTextarea {
  font-family: 'Dosis';
  color: var(--color-light);
  width: 100%;
  padding: 1rem;
  border: 1px solid var(--color-grey);
  border-radius: 5px;
  background-color: transparent;
}

.messageTextarea {
  resize: none;
  min-height: 200px;
}

.messageTextarea::placeholder {
  font-family: 'Dosis';
  font-size: 1rem;
}

.contactCardsFlex {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: stretch;
  height: 45rem;
}

.primaryCard {
  border-radius: var(--border-radius);
  flex: 2;
  aspect-ratio: 1 / 2;
  border: 1px solid var(--color-grey);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
}

.stackedCardsContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
}

.secondaryCard {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: var(--border-radius);
  border: 1px solid var(--color-grey);
  flex: 1;
  aspect-ratio: 1 / 1;
}

.connectCard {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;
  width: 100%;
  position: relative;
  gap: 1rem;
}

.buttonsContainer {
  gap: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
}

.zapButtons {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
}

/* Small Devices */
@media (max-width: 1024px)  {
  .mainTitle {
    padding: 2rem;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    text-align: center;
  }

  .mainTitle h1 {
    text-align: center;
    min-block-size: 20vh;
    font-weight: 500;
    line-height: 3.3rem;
    font-size: 3rem;
  }

  .mainTitle p {
    font-size: 1.4rem;
    line-height: 1.8rem;
    max-width: 100%;
  }

  .imageContainer {
    max-width: 100%;
    margin-top: 2rem;
  }
}


@media (max-width: 600px) {
.contactCardsFlex {
  height: 100rem;
  flex-direction: column;
}
}
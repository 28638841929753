.card {
    background: transparent;
    padding: 2rem 3rem;
    border: 1px dashed var(--color-light-grey);
    border-radius: var(--border-radius);
}

.cardFlex {
    text-align: start;
    gap: 1rem;
}

.card.ghost {
    position: absolute;
    background: var(--transparent-3);
    border: 1px solid var(--transparent-2);
    backdrop-filter: blur(10px);
}

.card.ghost p {
    font-size: 1.1rem;
    color: var(--color-light);
}
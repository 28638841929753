.reviewsContainer {
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}

.reviewsContainer h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.reviewsContainer p {
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-bottom: 2rem;
}

.reviewsGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  justify-content: center;
}

.reviewCard {
  justify-content: space-between;
  padding: 2rem;
  border-radius: var(--border-radius);
  text-align: left;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.reviewContent h2 {
  line-height: 1.8rem;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.reviewContent p {
  font-size: 1rem;
  line-height: 1.5rem;
}

.reviewerInfo {
  display: flex;
  align-items: center;
  margin-top: auto;
}

.reviewerAvatar {
  filter: grayscale(100%) brightness(1.4) contrast(0.7);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 1rem;
}

.reviewerDetails h3 {
  font-size: 1rem;
  margin: 0;
}

.reviewerDetails p {
  font-size: 0.875rem;
  color: var(--color-grey-2);
  margin: 0;
}

@media (max-width: 1025px) {
  .reviewsGrid {
    grid-template-columns: 1fr; 
    gap: 1rem;
    padding: 0;
    margin: 0;
  }

  .reviewCard {
    margin: 0;
    width: 100%;
  }
}
.cardTitle {
  justify-content: start;
  align-items: center;
  gap: 1rem;
}

.cardTitle h2{
  color: var(--color-primary);
  line-height: 2rem;
}

.cardTitle h3 {
  font-size: 1rem;
}

.cardIcon {
  font-size: 1.3rem;
  color: var(--color-grey-2);
  margin-right: 7px;
}

.cardFlex {
  gap: 1rem;
}

.iconsContainer {
  display: flex;
  align-items: center;
}

.servicesCards {
  gap: 2rem;
  flex-grow: 1;
}

.servicesCards > * {
  flex-grow: 1;
}


/* Small Devices */
@media (max-width: 1024px) {
  
  .serviceCard {
    padding: 2rem;
  }
}

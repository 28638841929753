.about {
    padding: 4rem 0rem;
  }

.innerAbout {
    padding: 5rem 6rem;
    background-color: var(--color-primary);
    border-radius: var(--border-radius);
}

.innerAbout img {
    align-self: center;
    max-width: 23rem;
    max-height: 30rem; 
    object-fit: cover;    
    object-position: top; 
}

.titleFlex {
    gap: 1rem;
    padding-left: 2.1rem;
    padding-right: 1.8rem;
    align-self: center;
}

.titleFlex h3 {
  line-height: 1.9rem;
}

.aboutButton {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

.titleFlex h1 {
    line-height: 4.4rem;
    font-size: 4rem;
}

.titleFlex p {
    font-size: 1.2rem;
}

@media (max-width: 1025px) {
.titleFlex h1 {
  line-height: 2.5rem;
    font-size: 2rem;
}

.innerAbout img {
  display: none;
}

.innerAbout {
  padding: 3rem;
}

.titleFlex {
  padding-left: 0;
  padding-right: 0;
}

}
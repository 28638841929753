footer {
    padding: 0 35rem;
    margin-bottom: 2rem;
  }

.contact {
  height: 100%;
  align-items: center;
  padding: 12rem 0;
}

.contact p {
  color: var(--color-grey-2);
  }

.contactButton {
  padding-top: 1rem;
}

.footerSocials a svg {
  margin-right: 0.5rem;
}

.contact h1 {
  line-height: 7rem;
  min-block-size: 25vh;
  text-align: center;
  font-weight: normal;
}

/* footer.css */

.footerMain {
  margin-bottom: 2rem;
  gap: 5rem
}

/* Left Column Styles */
.footerLeft {
  justify-content: space-between;
}

.footerLogo {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.footerSocials {
  align-items: flex-start;
  gap: 0.5rem;
}

.footerSocials a {
  color: var(--color-grey-2);
  text-decoration: none;
}

.footerSocials a:hover {
  color: var(--color-light);
}

/* Right Column Styles */
.footerRight {
  display: flex;
  gap: 4rem;
  flex: 1;
  justify-content: flex-end;
}

.footerColumn {
  gap: 0.5rem;
}

.footerColumn h4 {
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.footerColumn a {
  color: var(--color-grey-2);
  text-decoration: none;
}

.footerColumn a:hover {
  color: var(--color-light);
}

/* Bottom Row Styles */
.footerBottom {
  align-items: center;
  border-top: 1px solid var(--color-grey-3);
  padding-top: 1rem;
}

.footerBottom p {
  margin: 0;
  font-size: 0.875rem;
  color: var(--color-grey-2);
}

/* phones */
@media (max-width: 601px) {
  .footerMain {
    flex-direction: column;
    align-items: center;
  }

  .footerLeft{
    align-items: center;
  }
}

/* small Devices */
@media (max-width: 1025px) {
  footer {
    padding: 5rem 2rem;
  }

  .contact {
    padding: 0;
  }

  .contact h1 {
    line-height: 3rem;
    font-size: 3rem;
  }
  
  .footerMain {
    padding-top: 6rem;
  }
}
    
/* Normal Devices */
@media (min-width: 1025px) and (max-width: 2000px) {
  footer {
    padding: 5rem 16rem;
  }

  .contact h1 {
    min-block-size: 28vh;
  }
}

@media (min-width: 2000px) {
.contactButton {
  margin-top: -3rem;
}
}

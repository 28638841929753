.logoBar {
  display: flex;
  align-items: center;
  background-color: transparent;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100px;
  box-sizing: border-box;
}

.logoWrapper {
  flex: 1;
  overflow: hidden;
  display: flex;
}

.logos {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  white-space: nowrap;
}

.logoContainer {
  filter: brightness(0) invert(0);
  position: relative;
  margin: 0 15px;
  height: 8rem;
  width: auto;
  flex: 0 0 auto;
}

.logoContainer img {
  width: auto;
  height: 100%;
}

.fadeLeft, .fadeRight {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100px;
  pointer-events: none;
  z-index: 10;
}

.fadeRight {
  right: 0;
  background-image: linear-gradient(to left, var(--color-light), transparent);
}

.fadeLeft {
  background-image: linear-gradient(to right, var(--color-light), transparent);
}

/* Animation for rotation */
@keyframes rotateLogos {
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
}

/* Small Devices */
@media (max-width: 1024px) {
  .logos {
    animation: rotateLogos 10s linear infinite;
  }
}

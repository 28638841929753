.analytics {
    flex-wrap: wrap;
    text-align: center;
    justify-content: space-between;
}

.analytics h3 {
    color: var(--color-primary);
}

.analyticsItem {
    color: var(--color-primary);
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.servicesList {
    color: var(--color-grey);
    padding: 5rem 0;
}

.servicesList h3 {
    width: 20rem;
}

.servicesListFlex {
    display: flex;
}

.servicesListFlex p {
    width: 35rem;
    font-size: 1.1rem;
}

.servicesList h2 {
    font-size: 3rem;
}

.servicesListFlex:not(:last-child) {
    border-bottom: 1px dashed var(--color-light-grey);
    margin-bottom: 5rem;
    padding-bottom: 5rem;
}

/* Small Devices */
@media (max-width: 1025px) {
    .analyticsItem {
    flex: 1 1 45%;
    }

    .servicesList h3 {
        width: 100%;
    }

    .servicesListFlex {
        flex-direction: column;
    }
    
    .servicesListFlex p {
        width: 100%;
    }
  }  

@media (min-width: 1026px) {
    .serviceHeader {
        width: 40rem;
    }
}